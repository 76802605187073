<template>
	<div>
		<!-- 轮播图 -->
		<el-carousel :height="imgHeight+'px'" :interval="5000" style="z-index: -1;">
			<el-carousel-item v-for="(item,index) in bannerlist" :key="index">
				<img ref="imgHeight" style="width:100%;" :src="item.imageurl" mode="widthFix" />
			</el-carousel-item>
		</el-carousel>
		<div class="mian" v-loading.fullscreen.lock="fullscreenLoading">
			<div class="title">{{info.title}}</div>
			<div class="time">发表时间：{{changetime(info.createtime)}}</div>
			<div v-html="info.content"></div>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				imgHeight: '', //图片高度
				bannerlist: [], //轮播图列表
				fullscreenLoading: false,
				info: {}

			}
		},
		mounted() {
			this.getbanner();
			// 监听窗口变化，使得轮播图高度自适应图片高度
			window.addEventListener("resize", () => {
				this.getbanner();
			}, false);
		},
		destroyed() { //切换页面后销毁监听窗口事件
			window.removeEventListener("resize", this.getbanner, false);
		},
		created() {
			this.getinfo(this.$route.query.id);
		},
		methods: {
			//获取轮播图
			getbanner() {
				let data = {
					mark: 'index01'
				}
				this.$api.home.getAdsByMark(data).then(res => {
					if (res.code == 1) {
						this.bannerlist = res.data.data
						//获取到图片数据之后
						this.$nextTick(function() {
							// 获取窗口宽度*图片的比例，定义页面初始的轮播图高度 width=1920,height=480 图片的默认宽高
							this.imgHeight = document.body.clientWidth * 500 / 1920
						});
					}
				})
			},
			//获取详情信息
			getinfo(id) {
				this.fullscreenLoading = true;
				let data = {
					id: id
				}
				this.$api.home.articleInfo(data).then(res => {
					this.fullscreenLoading = false;
					if (res.code == 1) {
						this.info = res.data
					}
				})
			},
			changetime(timestamp) {
				// 此处时间戳以毫秒为单位
				let date = new Date(parseInt(timestamp) * 1000);
				let Year = date.getFullYear();
				let Moth = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1);
				let Day = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate());
				let Hour = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours());
				let Minute = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes());
				let Sechond = (date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds());
				let GMT = Year + '-' + Moth + '-' + Day + '   ' + Hour + ':' + Minute + ':' + Sechond;
				return GMT;
			}
		}
	}
</script>

<style scoped>
	.mian {
		width: 1200px;
		margin: 50px auto;
	}

	.title {
		font-size: 24px;
	}

	.time {
		font-size: 14px;
		color: #999999;
		margin: 10px 0 30px 0;
	}
</style>